<template>
  <transition name="dialog">
    <div class="dialog-mask">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <div class="dialog-header">
            <slot name="header"></slot>
            <div class="dialog-close" v-if="needCloseButton" @click="hide">
              <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; overflow: visible; fill: currentcolor;">
                <path d="M13.06 12l5.72-5.72c.292-.292.292-.767 0-1.06-.294-.293-.768-.293-1.06 0L12 10.94 6.28 5.22c-.293-.293-.767-.293-1.06 0-.293.293-.293.768 0 1.06L10.94 12l-5.72 5.72c-.293.292-.293.767 0 1.06.146.146.338.22.53.22s.384-.074.53-.22L12 13.06l5.72 5.72c.145.146.337.22.53.22.19 0 .383-.074.53-.22.292-.293.292-.768 0-1.06L13.06 12z"></path>
              </svg>
            </div>
          </div>
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    components: {
    },
    props: {
      buttonsType: {
        type: String,
        default() {
          return 'ok'
        }
      },
      needCloseButton: {
        type: Boolean,
        default() {
          return true
        }
      }
    },
    methods: {
      hide() {
        this.$emit('close');
      }
    },
  }
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .dialog {
    &-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-dark, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s ease;
    }

    &-close {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }

    &-wrapper {
      max-width: 90%;
    }

    &-container {
      position: relative;
      max-width: 800px;
      width: 500px;
      margin: 0px auto;
      padding: 32px;
      background-color: $color-light;
      border-radius: 8px;
      box-shadow: 0px 0px 0.5px 0px rgba($color-dark, 0.14), 0px 1px 1px 0px rgba($color-dark, 0.24);
      transition: $transition;

      @include breakpoint(550) {
        max-width: 100%;
        width: 100%;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        cursor: pointer;
      }
    }

    &-header h3 {
      font-size: 23px;
      line-height: 110%;
      margin-top: 0;
      color: $color-green-dark-apron;

      @include breakpoint(550) {
        font-size: 16px;
      }
    }

    &-body {
      margin: 20px 0;

      p {
        font-size: 16px;
        line-height: 24px;
        color: rgba($color-dark, 0.6);
      }
    }

    &-leave {
      &-active {
        opacity: 0;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button:last-child {
        margin-left: 20px;
      }
    }

    &__img {
      &-wrapper {
        width: 264px;
        height: 166px;
        background-color: rgb(233, 247, 214);
        border-radius: 10px;
      }
    }
  }


  .dialog-leave-active .dialog-container {
    transform: scale(1.1);
  }

  .dialog-enter-active .dialog-container {
    transition: 15s;
  }

</style>