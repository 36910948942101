<template>
  <div class="profile__cards-info-content" v-if="init">
    <div class="profile-title profile__cards-info-title">{{$t('cards.topUp.title')}}</div>
    <div class="profile__cards-info-content-parts">
      <div class="profile__cards-info-content-left">
        <template v-if="bankCards.length>0">
        <form  @submit.prevent="submit">
        <p style="color: red;">{{errorText}}</p>
        <div class="profile__cards-info-content-part-title part-title">{{$t('cards.topUp.balance')}}</div>
        <div class="profile__cards-info-content-part-choice">
          <Radio
              val="1"
              :label="$t('cards.topUp.labelType1')"
              v-model="model.type"
          />
          <Radio
              val="2"
              :label="$t('cards.topUp.labelType2')"
              v-model="model.type"
          />
        </div>

          <template v-if="model.type==1">
          <div class="profile__cards-info-content-part-sum">
            <div class="profile__cards-info-content-part-sum-title text">{{$t('cards.topUp.changeValue')}}:</div>
            <div class="profile__cards-info-content-part-sum-items">
              <div
                v-for="(val,key) in values"
               :key="key"
                class="profile__cards-info-content-part-sum-item"
                :class="{'profile__cards-info-content-part-sum-item_active':val==model.value}"
                @click="model.value=val"
              >
                {{ val }} тнг.
              </div>
            </div>
            <div class="profile__cards-info-content-part-sum-other">
              <div class="text">{{$t('cards.topUp.changeValueOther')}}:</div>
              <div class="profile__cards-info-content-part-sum-other-dropdown">
                <Input
                  type="number"
                  v-model="model.value"
                  :state="!validation.hasError('model.value')"
                  :error-text="validation.firstError('model.value')"
                ></Input>
                <div class="profile__cards-info-content-part-sum-other-dropdown-text">ТНГ.</div>
              </div>
            </div>
          </div>
            <Button
                visual-style="fill"
                :loading="isLoading"
            >
              {{$t('cards.topUp.btnPay')}}
            </Button>
          </template>
          <template v-else>
            <div class="profile__cards-info-content-part-sum">
            <template v-if="card.AssistIdentifier">
              <p>{{$t('cards.topUp.autoPay')}} №{{cardAutoPay.MaskNumber}}</p>
              <Button
                  visual-style="fill"
                  visual-type="secondary"
                  :loading="isLoadingDeactivateAutoPay"
                  @click="deactivateAutoPay()"
              >
                {{$t('cards.topUp.btnCancel')}}
              </Button>
            </template>
            <template v-else>

            <div class="profile__cards-info-content-part-sum-other">
              <div class="text">{{$t('cards.topUp.dateAutoPay')}}:</div>
              <div class="profile__cards-info-content-part-sum-other-dropdown">
                <Input
                    v-model="model.date"
                    :state="!validation.hasError('model.date')"
                    :error-text="validation.firstError('model.date')"
                    :mask="'##.##.####'"
                ></Input>
              </div>
              <div class="text">{{$t('cards.topUp.minBalance')}}:</div>
              <div class="profile__cards-info-content-part-sum-other-dropdown">
                <Input
                    type="number"
                    v-model="model.minBalance"
                    :state="!validation.hasError('model.minBalance')"
                    :error-text="validation.firstError('model.minBalance')"
                ></Input>
              </div>
              <div class="text">{{$t('cards.topUp.paySum')}}:</div>
              <div class="profile__cards-info-content-part-sum-other-dropdown">
                <Input
                    type="number"
                    v-model="model.value"
                    :state="!validation.hasError('model.value')"
                    :error-text="validation.firstError('model.value')"
                ></Input>
              </div>
            </div>
            </template>
            </div>
            <Button
                type="button"
                v-if="!card.AssistIdentifier"
                visual-style="fill"
                :loading="isLoading"
                @click="autoPay()"
            >
              {{$t('cards.topUp.autoPayEnable')}}
            </Button>
          </template>
        </form>
        </template>
        <template v-else>
          <div class="part-title">{{$t('cards.topUp.textAddCard')}}</div>
        </template>
      </div>
      <div class="profile__cards-info-content-right">
        <div class="profile__cards-info-content-part-title part-title">{{$t('cards.topUp.payBankCardTitle')}}</div>
        <div class="profile__cards-info-content-part-cards">
          <template v-if="bankCards.length==0">
            <p>Нет карты</p>
            <Button
              visual-type="secondary"
              visual-style="outline"
              @click="openModalAddCard=true"
            >
              Подключить карту
            </Button>
          </template>
          <template v-else>
            <div v-for="(bankCard,index) in bankCards"
                 :key="index"
                 class="profile__cards-info-content-part-card"
                 :class="{'profile__cards-info-content-part-card_active':bankCard.Id==activeBankCard.Id}"
                 @click="activeBankCard=bankCard"
            >
              <img v-if="bankCard.MaskNumber[0]=='5'" class="profile__cards-info-content-part-card-icon" src="@/assets/img/mastercard.png">
              <img v-if="bankCard.MaskNumber[0]=='4'" class="profile__cards-info-content-part-card-icon" src="@/assets/img/visa.png">
              <div class="profile__cards-info-content-part-card-num">* {{ bankCard.MaskNumber }}</div>
              <div class="profile__cards-info-content-part-card-delete" @click="deleteBankCard=bankCard;openModalDeleteCard=true">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0598 12.5L18.7798 6.77999C19.0718 6.48799 19.0718 6.01299 18.7798 5.71999C18.4858 5.42699 18.0117 5.42699 17.7197 5.71999L11.9998 11.44L6.27975 5.71999C5.98675 5.42699 5.51275 5.42699 5.21975 5.71999C4.92675 6.01299 4.92675 6.48799 5.21975 6.77999L10.9397 12.5L5.21975 18.22C4.92675 18.512 4.92675 18.987 5.21975 19.28C5.36575 19.426 5.55775 19.5 5.74975 19.5C5.94175 19.5 6.13375 19.426 6.27975 19.28L11.9998 13.56L17.7197 19.28C17.8647 19.426 18.0568 19.5 18.2498 19.5C18.4397 19.5 18.6328 19.426 18.7798 19.28C19.0718 18.987 19.0718 18.512 18.7798 18.22L13.0598 12.5Z" fill="black"/>
                </svg>
              </div>
            </div>
            <Button
                visual-type="secondary"
                visual-style="outline"
                @click="openModalAddCard=true"
            >
              {{$t('cards.topUp.addBankCard')}}
            </Button>
          </template>
        </div>
        <div class="profile__cards-info-content-part-note">
          <div
              class="profile__cards-info-content-part-note-text"
              :class="{ 'profile__cards-info-content-part-note-text_show': isNoteOpen }"
          >
            {{$t('cards.topUp.noteCard')}}
          </div>
          <div
              class="profile__cards-info-content-part-note-show"
              @click="isNoteOpen = true"
              v-if="!isNoteOpen"
          >
            {{$t('cards.topUp.noteCardShow')}}
          </div>
        </div>
        <div class="profile__cards-info-content-part-paysystems">
          <img class="profile__cards-info-content-part-paysystem" src="@/assets/img/visa-light.png">
          <img class="profile__cards-info-content-part-paysystem" src="@/assets/img/mastercard.png">
          <img class="profile__cards-info-content-part-paysystem" src="@/assets/img/verified-visa.png">
        </div>
      </div>
    </div>
    <div class="profile-separator"></div>
    <div class="profile__cards-info-currency">{{$t('cards.topUp.note')}}</div>
    <Dialog
        buttonsType="save"
        v-if="openModalDeleteCard"
        @close="openModalDeleteCard = false"
    >
      <div slot="header">
        <h3>{{$t('cards.topUp.modalDeleteCard')}}</h3>
      </div>
      <div slot="body">
        <DeleteBankCard
          :card="deleteBankCard"
          @cancel="openModalDeleteCard=false"
          @success="openModalDeleteCard=false"
        >
        </DeleteBankCard>
      </div>
    </Dialog>
    <Dialog
        v-if="openModalAddCard"
        @close="openModalAddCard = false"
    >
      <div slot="header">
        <h3>{{$t('cards.topUp.modalAddBankCard')}}</h3>
      </div>
      <div slot="body">
        <AddBankCard
            @cancel="openModalAddCard=false"
            @success="openModalAddCard=false"
            :cardId="card.Id"
        >
        </AddBankCard>
      </div>
    </Dialog>
    <Dialog
        v-if="openModalPaySuccess"
        @close="openModalPaySuccess = false"
    >
      <div slot="header">
        <h3>{{$t('cards.topUp.modalPaySuccess')}}</h3>
      </div>
      <div slot="body">
        <p>{{$t('cards.topUp.modalPaySuccessText')}}</p>
        <div class="dialog-footer">
          <div class="dialog__btns">
            <Button type="button" visual-style="fill" @click="openModalPaySuccess=false">{{$t('actions.ok')}}</Button>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>

import SimpleVueValidator from 'simple-vue-validator';
import Dialog from '@/components/Controlls/Dialog';
import DeleteBankCard from "@/components/cards/DeleteBankCard";
import AddBankCard from "@/components/cards/AddBankCard";
import ApiService from "@/services/api.service";
import {mapActions, mapGetters} from "vuex";

const Validator = SimpleVueValidator.Validator;

export default {
  props:['number'],
  components: {
    AddBankCard,
    Dialog,
    DeleteBankCard
  },
  data() {
    return {
      init:false,
      openModalDeleteCard:false,
      openModalAddCard:false,
      openModalPaySuccess:false,
      deleteBankCard:null,
      values:[
        1500,
        3000,
        10000,
        20000,
        30000
      ],
      model:{
        type:1,
        value:1500,
        date:null,
        minBalance:null,
      },
      isNoteOpen: false,
      submitted:false,
      isLoading:false,
      isLoadingDeactivateAutoPay:false,
      errorText:'',
      bankCards:[],
      activeBankCard:null,
    }
  },
  created() {
    ApiService.cardsSaved().then((resp)=>{
      let data = resp.data;
      if(data.value){
        this.bankCards = data.value;

        if(this.bankCards.length>0)
          this.activeBankCard=this.bankCards[0];
      }

      this.init=true;
    });
  },
  computed: {
    ...mapGetters({
      cards: "cards/cards"
    }),
    card(){
      for(let card of this.cards){
        if(card.Number == this.number)
          return card;
      }
      return null;
    },
    cardAutoPay(){
      if(this.card.AssistIdentifier){
        for(let bankCard of this.bankCards){
          if(bankCard.BillNumber==this.card.AssistIdentifier)
            return bankCard;
        }
      }

      return null;
    }
  },
  methods:{
    ...mapActions({
      'getCards':'cards/getCards'
    }),
    submit(){
      this.submitted = true;

      this.$validate().then(success => {
          if (success) {
            this.isLoading=true;
            this.errorText = '';

            ApiService.pay({
              type:this.model.type,
              value:this.model.value,
              cardId: this.card.Id,
              date: this.model.date,
              minBalance:this.model.minBalance,
              cardNumber:this.card.Number,
              billNumber: this.activeBankCard.BillNumber
            }).then(()=>{
                this.openModalPaySuccess=true;
                this.isLoading=false;

              window.dataLayer.push({'event':'Balance', 'eventCategory':'Balance', 'eventAction':'click', 'eventLabel':this.model.value})
            }).catch((err)=>{
              let data = err.data;
              this.errorText = data.errorText;
              this.isLoading=false;
            });

          }
        }
      )
    },
    bankCardPay(data){
      this.submitted = true;
      this.$validate().then(success => {
        if (success) {

          ApiService.fastPay({
            value:this.model.value,
            cardId: this.card.Id,
            parentBillNumber:data.BillNumber
          }).then((resp)=>{
            let data = resp.data;

            console.log(data);
            this.isLoading=false;
          }).catch((err)=>{
            let data = err.data;
            this.errorText = data.errorText;
            this.isLoading=false;
          });
        }
      });
    },
    autoPay(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {
              this.isLoading=true;
              this.errorText = '';

              ApiService.pay({
                type:this.model.type,
                value:this.model.value,
                cardId: this.card.Id,
                date: this.model.date,
                minBalance:this.model.minBalance,
                cardNumber:this.card.Number,
                billNumber: this.activeBankCard.BillNumber
              }).then(()=>{
                  this.getCards().then(()=>{
                    this.isLoading=false;
                  })
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.isLoading=false;
              });

            }
          }
      )
    },
    deactivateAutoPay(){

      this.isLoadingDeactivateAutoPay = true;

      ApiService.deactivateAutoPay({
        cardNumber:this.card.Number,
        billNumber:this.cardAutoPay.BillNumber
      })
      .then(()=>{
        this.getCards().then(()=>{
          this.isLoadingDeactivateAutoPay=false;
        })
      }).catch((err)=>{
        let data = err.data;
        this.errorText = data.errorText;
        this.isLoading=false;
      });
    }
  },
  validators: {
    "model.value": function (value) {
      if (this.submitted){
        return Validator.value(value).required(this.$t('errors.required')).greaterThan(0,'Некорректное значение')
      }
    },
    "model.date": function (value) {
      if(this.submitted && this.model.type==2)
        return Validator.value(value).required(this.$t('errors.required')).regex(/\d{2}.\d{2}.\d{4}/, this.$t('errors.date'));
    },
    "model.minBalance": function (value) {
      if(this.submitted && this.model.type==2)
        return Validator.value(value).required(this.$t('errors.required'))
    },
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>
