<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">{{$t('actions.cancel')}}</Button>
        <Button visual-style="fill" :loading="isLoading">{{$t('actions.delete')}}</Button>
      </div>
    </div>
  </form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props:['card'],
  components: {
  },
  data() {
    return {
      errorText:'',
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.isLoading=true;

      ApiService.deleteCreditCard({
        'billNumber':this.card.BillNumber
      }).then((resp)=>{
        this.$emit('success',resp.data);
        this.isLoading=false;
      }).catch((err)=>{
        let data = err.data;
        this.errorText = data.errorText;
        this.isLoading=false;
      });

    }
  },
  created() {
  },
}
</script>
