<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
    </div>
    <div class="dialog-body">
      <p>
        {{$t('components.cards.addBankCard.desc')}}
      </p>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">{{ $t('actions.cancel') }}</Button>
        <Button visual-style="fill" :loading="isLoading">{{ $t('actions.ok') }}</Button>
      </div>
    </div>
  </form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props:['cardId'],
  components: {
  },
  data() {
    return {
      errorText:'',
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.isLoading=true;

      ApiService.addCreditCard({
        'cardId':this.cardId
      }).then((resp)=>{

        let data = resp.data;

        if(data.redirectUrl)
          window.location.href=data.redirectUrl;

        this.isLoading=false;
      }).catch((err)=>{
        let data = err.data;
        this.errorText = data.errorText;
        this.isLoading=false;
      });

    }
  },
  created() {
  },
}
</script>
